<template>
  <div>

    <form-wizard
        color="#2d71b8"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Deploy"
        back-button-text="Previous"
        class="wizard-vertical mb-3"
        @on-complete="deployInstance"

    >
      <tab-content title="Subscriber Details" icon="feather icon-user-plus" :before-change="subscriberAdd">
        <validation-observer ref="userDetailsRules">
          <b-form>
            <b-row>
              <b-col
                  cols="12"
                  class="mb-2"
              >
                <h5 class="mb-0">
                  Subscriber Details
                </h5>
                <small class="text-muted">
                  Enter Subscriber's Information.
                </small>
              </b-col>
              <b-col md="6">

                <b-form-group
                    label="First Name"
                    label-for="fh-name"

                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required|alpha"
                      name="First Name"
                  >
                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-name"
                          placeholder="Karim"
                          v-model="user.first_name"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Last Name"
                    label-for="fh-username"
                >

                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserMinusIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-username"
                        placeholder="Abdullah"
                        v-model="user.last_name"
                    />
                  </b-input-group>

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Email"
                    label-for="fh-email"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required|email"
                      name="Email"
                  >
                    <b-input-group class="input-group-merge">

                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon"/>
                      </b-input-group-prepend>

                      <b-form-input
                          id="fh-email"
                          type="email"
                          placeholder="Enter a valid Email"
                          v-model="user.email"
                      />


                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Username"
                    label-for="fh-username"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Username"
                  >
                    <b-input-group class="input-group-merge">

                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserCheckIcon"/>
                      </b-input-group-prepend>

                      <b-form-input
                          id="fh-username"

                          placeholder="Enter a valid username"
                          v-model="user.username"
                      />


                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Password"
                    label-for="fh-password"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Password"
                  >

                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-password"
                          v-model="user.password"
                          type="password"
                          placeholder="Password"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Confirm Password"
                    label-for="fh-retype"
                >
                  <validation-provider
                      #default="{ errors }"
                      :rules="'required|regex:'+user.password"
                      name="Confirm Password"
                  >

                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-retype"
                          v-model="user.password2"
                          type="password"
                          placeholder="Re-type Password"
                      />

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>

      <!--       Business datails tab -->
      <tab-content title="Business Details" icon="feather icon-command" :before-change="businessCreate">
        <validation-observer ref="BusinessDetailsRules">
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h5 class="mb-0">
                Business Details
              </h5>
              <small class="text-muted">
                Enter Your Business Details.
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Business Name"
                  label-for="fh-businessname"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Business Name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HexagonIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-businessname"
                        placeholder="MyBusiness"
                        v-model=" business_info.business_name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Preferred Slug"
                  label-for="fh-contactnumber"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PhoneIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="fh-contactnumber"
                      placeholder="mybusiness"
                      v-model=" business_info.business_slug"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Country"
                  label-for="fh-country"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Country"
                >
                  <v-select
                      id="fh-country"
                      v-model=" business_info.business_country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="country"
                      :reduce="name=>name.id"
                      label="name"
                      @input="updateState"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label-for="fh-state"
                  label="State"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="State"
                >
                  <v-select
                      id="fh-state"
                      v-model=" business_info.business_state"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="state"
                      :reduce="name=>name.id"
                      label="name"
                      @input="updateArea"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Area"
                  label-for="fh-area"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Area"
                >
                  <v-select
                      id="fh-area"
                      v-model=" business_info.business_area"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="area"
                      :reduce="name=>name.id"
                      label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label-for="fh-type"
                  label="Business Type"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Business Type"
                >
                  <v-select
                      id="fh-type"
                      v-model=" business_info.business_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="businessTypes"
                      :reduce="business_type=>business_type.id"
                      label="business_type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content title="Subscription" icon="feather icon-target" :before-change="subscriptionInfoUpdate">
        <validation-observer ref="subscriptionDetails">
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h5 class="mb-0">
                Business Applicable Subscription Selection
              </h5>
              <small class="text-muted">Select Subscription Plan.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label-for="fh-subscription"
                  label="Subscription Plan Pricing"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="subscription"
                >
                  <v-select
                      id="fh-subscription"
                      v-model=" subscription_info.subscription_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pricing"
                      :reduce="pricing=>pricing.id"
                      label="pricing"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="deployed" class="justify-content-center">
            <span>You have <span class="text-success text-bold">SUCCESSFULLY</span> deployed a business.</span>
          </b-row>
          <b-row v-if="deployed" class="justify-content-center">
            <span>To <span class="text-success text-bold"> Onboard another business</span> click <span
                class="text-primary text-bold">Onboard Another </span> Button.</span>
          </b-row>
          <b-row v-if="deployed" class="justify-content-center mt-75">
            <b-button variant="primary" @click="addAnother">Onboard Another</b-button>
          </b-row>
        </validation-observer>
      </tab-content>

<!--      <tab-content title="Theme Selection" icon="feather icon-target" :before-change="themeInfoUpdate">-->
<!--        <validation-observer ref="themeDetailsRules">-->
<!--          <b-row>-->
<!--            <b-col-->
<!--                cols="12"-->
<!--                class="mb-2"-->
<!--            >-->
<!--              <h5 class="mb-0">-->
<!--                Business Applicable Theme & Subscription Selection-->
<!--              </h5>-->
<!--              <small class="text-muted">Select Suitable Theme.</small>-->
<!--            </b-col>-->
<!--            <b-col md="6">-->
<!--              <b-form-group-->
<!--                  label-for="fh-theme"-->
<!--                  label="Theme"-->
<!--              >-->
<!--                <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    rules="required"-->
<!--                    name="Theme"-->
<!--                >-->
<!--                  <v-select-->
<!--                      id="fh-theme"-->
<!--                      v-model=" theme_info.theme_id"-->
<!--                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                      :options="theme"-->
<!--                      :reduce="name=>name.id"-->
<!--                      label="name"-->
<!--                  />-->
<!--                  <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </validation-provider>-->
<!--              </b-form-group>-->
<!--            </b-col>-->

<!--          </b-row>-->

<!--        </validation-observer>-->
<!--      </tab-content>-->

    </form-wizard>


  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BImg, BContainer
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mapState} from "vuex";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BFormTextarea,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BImg, BContainer,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  watch: {},
  created() {
    this.$store.dispatch('area/fetchArea')
    this.$store.dispatch('state/fetchState')
    this.$store.dispatch('country/fetchCountry')
    this.$store.dispatch('businessType/fetchBusinessTypes')
    this.$store.dispatch('theme/fetchTheme')
    this.$store.dispatch('pricing/fetchPricing')
  },
  computed: {
    ...mapState('area', ['area', "areaError", "areaLoading"]),
    ...mapState('state', ['state', "stateError", "stateLoading"]),
    ...mapState('country', ['country', "countryError", "countryLoading"]),
    ...mapState('businessType', ['businessTypes', "businessTypeError", "businessTypeLoading"]),
    ...mapState('theme', ['theme', "themeLoading", "themeError"]),
    ...mapState('pricing', ['pricing', "pricingError", "pricingLoading"]),
  },
  data() {
    return {
      business_info: {
        user: null,
        business_name: null,
        business_country: null,
        business_state: null,
        business_area: null,
        business_type: null,
        business_slug: null
      },
      theme_info: {
        user: null,
        theme_id: null
      },
      subscription_info: {
        user: null,
        subscription_id: null
      },

      user: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        password2: "",
      },

      confirm_password: "",

      ownerAdded: false,
      businessAdded: false,
      themeAdded: false,
      deployed: false,
      brandAdded: false,
      socialAdded: false,
      subscriptionAdded: false,

      user_id: null,
      business_slug: null,


    }
  },
  methods: {

    updateArea() {
      this.$store.dispatch('area/fetchAreaByState', {state_id: this.business_info.business_state})
    },
    updateState() {
      this.$store.dispatch('state/fetchStateByCountry', {country_id: this.business_info.business_country})
    },
    deleteImg(field) {
      if (field == 'logo') {
        this.$store.dispatch('image/deleteImage', this.logo_img_id).then(result => {
          if (result.code == 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-end',
              props: {
                title: 'Upload another',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: result.message,
              },
            })
            this.logo_img_name = "http://via.placeholder.com/400x400"

          }
        })
      } else {
        this.$store.dispatch('image/deleteImage', this.cover_img_id).then(result => {
          if (result.code == 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-end',
              props: {
                title: 'Upload another',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: result.message,
              },
            })
            this.cover_img_name = "http://via.placeholder.com/1200x400"
          }
        })

      }

    },
    previewFile(event) {
      const file = document.querySelector('input[type=file]').files[0];
      if (file) {
        this.imageRead(file).then(result => {
          this.$store.dispatch('image/addImage', result).then(result => {
            if (Array.isArray(result)) {
              result.forEach(e => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-end',
                  props: {
                    title: e.field + ' Error',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: e.message,
                  },
                })
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: result.code == 400 ? 'Error' : `Success`,
                  icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                  variant: result.code == 400 ? 'danger' : 'success',
                  text: result.message,
                },
              })
              if (result.code == 200) {
                this.logo_img_name = result.image.image
                this.logo_img_id = result.image.id
              }
            }
          })
        })
      }

    },
    previewCoverFile(event) {
      const file = document.querySelector('input[type=file]').files[0];
      if (file) {
        this.imageRead(file).then(result => {
          this.$store.dispatch('image/addImage', result).then(result => {
            if (Array.isArray(result)) {
              result.forEach(e => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-end',
                  props: {
                    title: e.field + ' Error',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: e.message,
                  },
                })
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: result.code == 400 ? 'Error' : `Success`,
                  icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                  variant: result.code == 400 ? 'danger' : 'success',
                  text: result.message,
                },
              })
              if (result.code == 200) {
                this.cover_img_name = result.image.image
                this.cover_img_id = result.image.id
              }
            }
          })
        })
      }
    },
    imageRead(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result)
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    addAnother() {
      this.$router.go();
    },
    subscriberAdd() {
      if (this.ownerAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.userDetailsRules.validate().then(async success => {
            if (success) {
              await this.$store.dispatch('onboard/addSubscriber', this.user).then(result => {
                if (result.code != 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-end',
                    props: {
                      title: ' Error',
                      icon: 'AlertOctagonIcon',
                      variant: 'danger',
                      text: result.response,
                    },
                  })
                  reject()
                } else {
                  this.business_info.user = result.user_info.id
                  this.theme_info.user = result.user_info.id
                  this.subscription_info.user = result.user_info.id
                  this.user_id = result.user_info.id
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: result.code == 400 ? 'Error' : `Success`,
                      icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                      variant: result.code == 400 ? 'danger' : 'success',
                      text: result.response,
                    },
                  })
                  this.ownerAdded = true
                  resolve(true)
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },

    businessCreate() {
      if (this.businessAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.BusinessDetailsRules.validate().then(async success => {
            if (success) {
              await this.$store.dispatch('onboard/addBusiness', this.business_info).then(result => {

                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: result.code == 400 ? 'Error' : `Success`,
                    icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                    variant: result.code == 400 ? 'danger' : 'success',
                    text: result.message,
                  },
                })
                if (result.code === 200) {
                  console.log(result)
                  this.business_slug = result.data.business_slug
                  this.businessAdded = true
                  resolve(true)

                } else {
                  reject()
                }

              })
            } else {
              reject()
            }
          })
        })
      }
    },
    themeInfoUpdate() {
      if (this.themeAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.themeDetailsRules.validate().then(async success => {
            if (success) {
              await this.$store.dispatch('onboard/selectTheme', this.theme_info
              ).then(result => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: result.code == 400 ? 'Error' : `Success`,
                    icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                    variant: result.code == 400 ? 'danger' : 'success',
                    text: result.message,
                  },
                })
                if (result.code === 200) {
                  this.themeAdded = true
                  resolve(true)
                } else {
                  reject()
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },
    subscriptionInfoUpdate() {
      if (this.subscriptionAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.subscriptionDetails.validate().then(async success => {
            if (success) {
              console.log(this.subscription_info)
              await this.$store.dispatch('onboard/selectSubscription', this.subscription_info
              ).then(result => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: result.code == 400 ? 'Error' : `Success`,
                    icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                    variant: result.code == 400 ? 'danger' : 'success',
                    text: result.message,
                  },
                })
                if (result.code === 200) {
                  this.subscriptionAdded = true
                  resolve(true)
                } else {
                  reject()
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },
    deployInstance() {
      // this.$store.dispatch('onboard/createDeploymentUrl', {
      //       "user": this.theme_info.user,
      //       "front_end_url": this.business_slug + ".nextcart.shop",
      //       "back_end_url": "api-" + this.business_slug + ".nextcart.shop"
      //     }
      // )
      //   if (result.code === 200) {
      console.log('hello')
      this.deployed = true
      this.$store.dispatch("onboard/deployInstanceOnServer", {user: this.business_info.user }).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code == 400 ? 'Error' : `Success`,
            icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
            variant: result.code == 400 ? 'danger' : 'success',
            text: result.message,
          },
        })
        if (result.code === 200) {
          resolve(true)
        } else {
          reject()
        }
      })

    },
    idInfoUpdate() {
      if (this.idAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.IdDetailsRules.validate().then(async success => {
            if (success) {
              this.business_id_info['business_hash'] = this.update.hash


              await this.$store.dispatch('business/updateBusiness', this.business_id_info
              ).then(result => {
                if (Array.isArray(result)) {
                  result.forEach(e => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-end',
                      props: {
                        title: e.field + ' Error',
                        icon: 'AlertOctagonIcon',
                        variant: 'danger',
                        text: e.message,
                      },
                    })
                  })
                } else {
                  console.log(result)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: result.code == 400 ? 'Error' : `Success`,
                      icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                      variant: result.code == 400 ? 'danger' : 'success',
                      text: result.message,
                    },
                  })
                  if (result.code === 200) {
                    this.idAdded = true
                    resolve(true)

                  } else {
                    reject()
                  }
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },
    brandInfoUpdate() {
      if (this.brandAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        this.business_brand_info.logo.image = this.logo_img_name
        this.business_brand_info.cover.image = this.cover_img_name
        return new Promise((resolve, reject) => {
          this.$refs.BrandDetailsRules.validate().then(async success => {
            if (success) {
              this.business_brand_info['business_hash'] = this.update.hash
              await this.$store.dispatch('business/updateBusiness', this.business_brand_info).then(result => {
                if (Array.isArray(result)) {
                  result.forEach(e => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-end',
                      props: {
                        title: e.field + ' Error',
                        icon: 'AlertOctagonIcon',
                        variant: 'danger',
                        text: e.message,
                      },
                    })
                  })
                } else {
                  console.log(result)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: result.code == 400 ? 'Error' : `Success`,
                      icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                      variant: result.code == 400 ? 'danger' : 'success',
                      text: result.message,
                    },
                  })
                  if (result.code === 200) {
                    this.brandAdded = true
                    resolve(true)

                  } else {
                    reject()
                  }
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },
    socialAdd() {
      if (this.socialAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.SocialDetailsRules.validate().then(async success => {
            if (success) {

              this.business_social_link['business_hash'] = this.update.hash
              await this.$store.dispatch('business/updateBusiness', this.business_social_link).then(result => {
                if (Array.isArray(result)) {
                  result.forEach(e => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-end',
                      props: {
                        title: e.field + ' Error',
                        icon: 'AlertOctagonIcon',
                        variant: 'danger',
                        text: e.message,
                      },
                    })
                  })
                } else {
                  console.log(result)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: result.code == 400 ? 'Error' : `Success`,
                      icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                      variant: result.code == 400 ? 'danger' : 'success',
                      text: result.message,
                    },
                  })
                  if (result.code === 200) {
                    this.socialAdded = true
                    resolve(true)

                  } else {
                    reject()
                  }
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-profile.scss';
</style>
